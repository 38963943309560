import { createAsyncThunk, createSlice, isDraft } from '@reduxjs/toolkit';
import axiosInstance from 'api/axios';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
};

export const getAllConfigVariable = createAsyncThunk('profile/configVariable', async (data, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post('/config-variables/list', data);
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const createProfile = createAsyncThunk('profile/createProfile', async (data, { rejectWithValue }) => {
	const reqBody = {
		...data,
		isCreatedByEmployee: true
	}
	try {
		const response = await axiosInstance.post('/profiles/create', reqBody);
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const ProfileLists = createAsyncThunk('profile/profileList', async (filter, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post(`/profiles/list?sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}&page=${filter.page ?? 1}&pageSize=${filter.pageSize ?? 10}`, {
			status: filter.profileReviewStatus,
			isActive: filter.isActive,
			branch: filter?.branch ?? "",
			startDate: filter.startDate,
			endDate: filter.endDate,
			searchQuery: filter.searchQuery,
			leadType: filter?.leadType ?? "",
			leadStatusType: filter?.leadStatusType ?? "",
			salesAdvisor: filter?.salesAdvisor ?? "",
			// serviceAdvisor: filter?.serviceAdvisor ?? "",
			refLeadSource: filter?.refLeadSource ?? "",
			gender: filter.gender,
			caste: filter.caste,
			religion: filter.religion,
			subCaste: filter.subCaste,
			motherTongue: filter.motherTongue,
			maritalStatus: filter.maritalStatus,
			age: filter.age,
			height: filter.height,
			weight: filter.weight,
			bodyType: filter.bodyType,
			complexion: filter.complexion,
			isPhysicallyChallenged: filter.isPhysicallyChallenged,
			city: filter.city,
			state: filter.state,
			country: filter.country,
			bloodGroup: filter.bloodGroup,
			qualification: filter.qualification,
			occupation: filter.occupation,
			workLocation: filter.workLocation,
			annualIncome: filter.annualIncome,
			birthPlace: filter.birthPlace,
			birthTime: filter.birthTime,
			diet: filter.diet,
			isDraft: filter?.isDraft ?? false,
			sortByPref: filter?.sortByPref ?? false,
		});
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const getProfileById = createAsyncThunk('profile/getProfileId', async (data, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.get(`/profiles/${data}?isFrontend=true`);
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const deleteProfile = createAsyncThunk('profile/deleteProfile', async (data, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.delete(`/profiles/delete/${data}`);
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const updateProfile = createAsyncThunk('profile/updateProfile', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.put(`/profiles/update/${id}`, data);
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const getSharedViewedProfiles = createAsyncThunk('profile/getSharedViewedProfiles', async (data, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post(`/share-user-profiles/list`, data);
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const shareUserProfile = createAsyncThunk('profile/shareUserProfile', async (data, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post(`/share-user-profiles/create`, data);
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		showLoading: (state) => {
			state.loading = true;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllConfigVariable.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllConfigVariable.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllConfigVariable.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(createProfile.pending, (state) => {
				state.loading = true;
			})
			.addCase(createProfile.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(createProfile.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(ProfileLists.pending, (state) => {
				state.loading = true;
			})
			.addCase(ProfileLists.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(ProfileLists.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(getProfileById.pending, (state) => {
				state.loading = true;
			})
			.addCase(getProfileById.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getProfileById.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(deleteProfile.pending, (state) => {
				state.loading = true;
			})
			.addCase(deleteProfile.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(deleteProfile.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(updateProfile.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateProfile.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(updateProfile.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			});
	},
});

export const { showLoading } = profileSlice.actions;

export default profileSlice.reducer;
